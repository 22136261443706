import cl from 'classnames';
import { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../list/ItemMessagesList';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { isChatGptMessagesMode } from '../../../utils/isChatGptMessagesMode';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { chatGptMessagesTypes } from '../../../messagesConstants';

import { words } from '../../../../../locales/keys';

interface MessagesChatGptModeFilterButtonProps {
  filterMessages: ItemMessagesListFilterMessages;
  messagesFilters: ItemMessagesFilters;
}

function MessagesChatGptModeFilterButton({
  filterMessages,
  messagesFilters
}: MessagesChatGptModeFilterButtonProps) {
  const currentUser = useCurrentUser();

  const isChatGptMode = isChatGptMessagesMode({ messagesFilters });

  const handleChatGptMode = useCallback<() => void>(() => {
    const newMessagesFilters = isChatGptMode
      ? {
          chatGptTargetUserId: undefined,
          messageTypeTextFilter: { notIn: chatGptMessagesTypes }
        }
      : {
          chatGptTargetUserId: { eq: currentUser?.id },
          messageTypeTextFilter: { in: chatGptMessagesTypes }
        };

    filterMessages({
      ...messagesFilters,
      ...newMessagesFilters
    });
  }, [currentUser?.id, filterMessages, isChatGptMode, messagesFilters]);

  return (
    <PureIconButtonHelper
      className={cl(
        'py-1 pl-1 pr-1 sm:pr-2 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base',
        isChatGptMode
          ? 'text-white bg-purple-500 hover:bg-purple-600 hover:shadow-md shadow-sm'
          : 'hover:text-gray-950 dark:hover:text-white focus:ring-offset-0'
      )}
      icon={isChatGptMode ? IconsEnum.X : IconsEnum.CHAT_GPT}
      iconClassName="h-4 w-4"
      i18nTextClassName="sr-only sm:not-sr-only"
      i18nText={words.gpt}
      onClick={handleChatGptMode}
    />
  );
}

export default MessagesChatGptModeFilterButton;
