import {
  BooleanFilter,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  IdFilterType,
  NanoIDFilter,
  NanoIdFilterType,
  TextFilterType,
  UUIDFilter,
  UuidFilterType
} from '../../types';
import {
  MessageBodyFilter,
  MessageCreatedAtFilter,
  MessageFavoriteFilter,
  MessageFlaggedFilter,
  MessageIdsFilter,
  MessageProjectMemberFilter,
  MessageReadFilter,
  MessageTagIdsFilter,
  MessageTaskMemberFilter,
  MessageThreadKeyIdFilter,
  MessageThreadStartFilter,
  MessageTypeFilter,
  MessageUnreadFilter,
  MessageUserUUIDsFilter,
  MessageVisibleForClientFilter,
  MessageWhiteboardKeyIdFilter,
  MessageWithAttachmentsFilter,
  MessageWithHoursOrMinutesFilter,
  MessageWithoutHoursAndMinutesFilter
} from '../messages/messagesTypes';
import { TeamNanoID } from '../teams/teamsTypes';

export type FetchTaskMessagesCacheKey = FetchItemsCacheKey;

export type FetchTaskMessagesGqlQuery = FetchItemsGqlQuery;

export type FetchTaskMessagesLimit = FetchItemsLimit;

export const enum FetchTaskMessagesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export interface FetchTaskMessagesFilters {
  body?: MessageBodyFilter;
  createdAt?: MessageCreatedAtFilter;
  favorite?: MessageFavoriteFilter;
  flagged?: MessageFlaggedFilter;
  flaggedOrPinned?: BooleanFilter;
  id?: MessageIdsFilter;
  isResult?: BooleanFilter;
  messageType?: MessageTypeFilter;
  messageTypeTextFilter?: TextFilterType;
  onlyClients?: BooleanFilter;
  pinned?: BooleanFilter;
  projectMember?: MessageProjectMemberFilter;
  projectNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  tagIds?: MessageTagIdsFilter;
  taskMember?: MessageTaskMemberFilter;
  taskNanoId?: NanoIDFilter;
  taskUuid?: UUIDFilter;
  teamNanoIds?: TeamNanoID[];
  threadKeyId?: MessageThreadKeyIdFilter;
  threadStart?: MessageThreadStartFilter;
  unread?: MessageUnreadFilter;
  read?: MessageReadFilter;
  userUuids?: MessageUserUUIDsFilter;
  visibleForClient?: MessageVisibleForClientFilter;
  whiteboardKeyId?: MessageWhiteboardKeyIdFilter;
  withAttachments?: MessageWithAttachmentsFilter;
  withHoursOrMinutes?: MessageWithHoursOrMinutesFilter;
  withoutHoursAndMinutes?: MessageWithoutHoursAndMinutesFilter;

  project?: {
    nanoId?: NanoIdFilterType;
    uuid?: UuidFilterType;
    member?: BooleanFilter;
  };
  tagId?: IdFilterType;
  task?: {
    nanoId?: NanoIdFilterType;
    uuid?: UuidFilterType;
    member?: BooleanFilter;
  };
  userId?: IdFilterType;
}
