import cl from 'classnames';

import { ItemMessagesListInvoiceMessageHeaderMessage } from './ItemMessagesListInvoiceMessageHeader.types';

import { DateField } from '../../../../../../../../../helpers/FormFields/DateField';
import { TextField } from '../../../../../../../../../helpers/FormFields/TextField';
import { Translate } from '../../../../../../../../../helpers/Translate';

import {
  InvoiceReturnReasons,
  invoiceReturnReasonTexts,
  InvoiceStatuses,
  invoiceStatusTexts,
  invoiceStatusTextStyles
} from '../../../../../../../../invoices/invoicesTypes';

import { invoicesKeys, words } from '../../../../../../../../../locales/keys';
import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { InvoiceCustomFieldsList } from '../../../../../../../../invoiceCustomFields/helpers/InvoiceCustomFieldsList';
import { CustomFieldLocations } from '../../../../../../../../customFields/customFieldsTypes';
import { MessagesPermissions } from '../../../../../../../messagesConstants';

const dueDateToBeDetermined = 'TBD';

interface ItemMessagesListInvoiceMessageHeaderProps {
  message: ItemMessagesListInvoiceMessageHeaderMessage;
}
function ItemMessagesListInvoiceMessageHeader({
  message
}: ItemMessagesListInvoiceMessageHeaderProps) {
  const invoice = message?.bitInvoice;

  const primaryInvoiceStatus = invoice?.clientStatus || invoice?.status;

  const secondaryInvoiceStatus =
    invoice?.secondaryInvoice?.clientStatus ||
    invoice?.secondaryInvoice?.status;

  const invoiceStatus =
    secondaryInvoiceStatus === InvoiceStatuses.PAID
      ? secondaryInvoiceStatus
      : primaryInvoiceStatus;

  const invoiceId = message.invoice?.clientId || message.invoiceId;

  const invoiceReturnReason = message.invoice?.returnReason;

  const invoiceReturnReasonText = invoiceReturnReasonTexts[invoiceReturnReason];

  return (
    <div className="flex-1">
      <div className="space-y-2">
        <h1 className="text-2xl font-medium">
          <Translate id={invoicesKeys.singular} />
          &nbsp;
          {`#${invoiceId}`}
        </h1>

        <div>
          {/* Issue Date */}
          <DateField
            className="text-xs flex gap-2"
            dateClassName="text-gray-600 dark:text-gray-400"
            i18nLabel={invoicesKeys.issueDateColon}
            i18nLabelClassName="font-semibold"
            type="date"
            value={invoice?.issueDate}
          />

          {/* Due Date */}
          <DateField
            className="text-xs flex gap-2"
            dateClassName="text-gray-600 dark:text-gray-400"
            i18nLabel={invoicesKeys.dueDateColon}
            i18nLabelClassName="font-semibold"
            nullValue={dueDateToBeDetermined}
            type="date"
            value={invoice?.dueDate}
          />

          <CheckPermissions
            action={
              MessagesPermissions.READ_MESSAGE_INVOICE_HEADER_CUSTOM_FIELDS
            }
          >
            <InvoiceCustomFieldsList
              listClassName="block"
              fieldClassName="text-xs flex gap-2"
              invoiceCustomFields={invoice?.invoiceCustomFields}
              customFieldsLocation={CustomFieldLocations.HEADER}
            />
          </CheckPermissions>
        </div>

        {/* Status */}
        <div>
          <TextField
            className={cl('text-xs', invoiceStatusTextStyles[invoiceStatus])}
            i18nLabel={words.statusColon}
            value={invoiceStatusTexts[invoiceStatus] || '?'}
            wrapperClassName="flex gap-2"
          />
        </div>

        {/*  Return reason */}
        {invoiceReturnReasonText &&
          invoiceReturnReason !== InvoiceReturnReasons.NONE && (
            <div className="px-4 py-1.5 rounded-full text-red-800 bg-red-200 inline-block">
              {invoiceReturnReasonText}
            </div>
          )}
      </div>
    </div>
  );
}

export default ItemMessagesListInvoiceMessageHeader;
