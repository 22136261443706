import { gql } from 'graphql-request';

import {
  MessageID,
  MessageUUID,
  MessageBody,
  MessageMessageType,
  MessageFavorite,
  MessageFlagged,
  MessagePinnedAt,
  MessageResentAt,
  MessageVisibleForClient,
  MessageColors,
  MessageCreatedAt,
  MessageHours,
  MessageMinutes,
  MessageTaskID,
  MessageIsResult,
  MessageLikeItems,
  MessageThreadKeyID,
  MessageWhiteboardKeyID,
  MessageThreadStart,
  MessageRed,
  MessageSortType,
  MessageResultVersion,
  MessageCreatedProductID,
  MessageClassification,
  MessageAiNotRelevantAt,
  MessageAssociations,
  MessageArchivedAt
} from '../../messages/messagesTypes';

export interface FetchTaskMessagesWithAssociationsQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  messageType: MessageMessageType;
  favorite: MessageFavorite;
  flagged: MessageFlagged;
  archivedAt?: MessageArchivedAt;
  pinnedAt?: MessagePinnedAt;
  resentAt?: MessageResentAt;
  visibleForClient: MessageVisibleForClient;
  colors: MessageColors;
  createdAt: MessageCreatedAt;
  createdProductId: MessageCreatedProductID;
  classification: MessageClassification;
  aiNotRelevantAt?: MessageAiNotRelevantAt;
  hours?: MessageHours;
  minutes?: MessageMinutes;
  taskId: MessageTaskID;
  isResult: MessageIsResult;
  resultVersion: MessageResultVersion;
  messageLikes: MessageLikeItems;
  threadKeyId: MessageThreadKeyID;
  whiteboardKeyId: MessageWhiteboardKeyID;
  threadStart: MessageThreadStart;
  red?: MessageRed;
  sortType: MessageSortType;
  associations?: MessageAssociations;
}

export const FETCH_TASK_MESSAGES_WITH_ASSOCIATIONS = gql`
  query TaskMessagesWithAssociations(
    $filters: TaskMessagesFilters
    $sort: [TaskMessagesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    taskMessages(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        body
        messageType
        favorite
        flagged
        archivedAt
        pinnedAt
        resentAt
        visibleForClient
        colors
        createdAt
        createdProductId
        classification
        aiNotRelevantAt
        taskId
        hours
        minutes
        isResult
        resultVersion
        threadKeyId
        whiteboardKeyId
        threadStart
        red
        sortType
        associations
      }
      paginationInfo {
        nextPage
      }
    }
  }
`;
