import { useCallback } from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TaskNanoID } from '../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../projects/projectsTypes';
import {
  FetchUsersErrorMessage,
  FetchUsersFetched,
  FetchUsersTotalCount
} from '../../../../users/usersTypes';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages,
  ItemMessagesListLastMessage,
  ItemMessagesListMessages,
  ItemMessagesListMessagesFetched
} from '../../list/ItemMessagesList';

import { FETCH_MESSAGES_TOTAL_COUNT_QUERY } from '../../../queries/fetchMessagesTotalCount.query';

import { useTotalsQuery } from '../../../../common/hooks/base/reactQuery/useTotalsQuery';

import { MessagesSettingsModalButton } from '../../modalButtons/MessagesSettingsModalButton';
import { ItemMessagesHeaderUsersFilter } from './components/ItemMessagesHeaderUsersFilter';
import { ItemMessagesHeaderVisibleForClientsFilter } from './components/ItemMessagesHeaderVisibleForClientsFilter';
import { ItemMessagesHeaderFilterButton } from './components/ItemMessagesHeaderFilterButton';
import { ItemMessagesHeaderJumpToButton } from './components/ItemMessagesHeaderJumpToButton';
import {
  MessagesFilterModalButton,
  MessagesFilterModalButtonUsers
} from '../../modalButtons/MessagesFilterModalButton';
import { ItemMessagesSavedFiltersButton } from '../../buttons/ItemMessagesSavedFiltersButton';
import { MessagesChatGptModeFilterButton } from '../../modalButtons/MessagesChatGptModeFilterButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { isChatGptMessagesMode } from '../../../utils/isChatGptMessagesMode';

import { MessagesPermissions } from '../../../messagesConstants';
import { ProjectCache } from '../../../../projects/ProjectCache';
import { TaskCache } from '../../../../tasks/TaskCache';

import { words } from '../../../../../locales/keys';

interface ItemMessagesHeaderDefaultProps {
  users: MessagesFilterModalButtonUsers;
  usersError: FetchUsersErrorMessage;
  usersFetched: FetchUsersFetched;
  usersTotalCount: FetchUsersTotalCount;
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
  onClickPinnedButton: () => void;
  messages: ItemMessagesListMessages;
  messagesFetched: ItemMessagesListMessagesFetched;
  lastMessages: ItemMessagesListLastMessage[];
  lastMessagesFetched: ItemMessagesListMessagesFetched;
}

interface ItemMessagesHeaderDefaultTaskProps {
  taskNanoId: TaskNanoID;
  projectNanoId?: never;
}

interface ItemMessagesHeaderDefaultProjectProps {
  projectNanoId: ProjectNanoID;
  taskNanoId?: never;
}

type ItemMessagesHeaderProps = ItemMessagesHeaderDefaultProps &
  (ItemMessagesHeaderDefaultTaskProps | ItemMessagesHeaderDefaultProjectProps);

function ItemMessagesHeader({
  projectNanoId,
  taskNanoId,
  users,
  usersError,
  usersFetched,
  usersTotalCount,
  messagesFilters,
  filterMessages,
  onClickPinnedButton,
  messages,
  messagesFetched,
  lastMessages,
  lastMessagesFetched
}: ItemMessagesHeaderProps) {
  const isChatGptMode = isChatGptMessagesMode({ messagesFilters });

  const starredMessagesCacheKey = projectNanoId
    ? ProjectCache.favoriteMessagesCacheKey(projectNanoId)
    : TaskCache.favoriteMessagesCacheKey(taskNanoId);

  const flaggedMessagesCacheKey = projectNanoId
    ? ProjectCache.flaggedMessagesCacheKey(projectNanoId)
    : TaskCache.flaggedMessagesCacheKey(taskNanoId);

  const pinnedMessagesCacheKey = projectNanoId
    ? ProjectCache.pinnedMessagesCacheKey(projectNanoId)
    : TaskCache.pinnedMessagesCacheKey(taskNanoId);

  const { data: starred } = useTotalsQuery({
    query: FETCH_MESSAGES_TOTAL_COUNT_QUERY,
    filters: {
      messagesFilters: {
        projectNanoId,
        taskNanoId,
        favorite: true
      }
    },
    cacheKey: starredMessagesCacheKey
  });

  const { data: flagged } = useTotalsQuery({
    query: FETCH_MESSAGES_TOTAL_COUNT_QUERY,
    filters: {
      messagesFilters: {
        projectNanoId,
        taskNanoId,
        flagged: true
      }
    },
    cacheKey: flaggedMessagesCacheKey
  });

  const { data: pinned } = useTotalsQuery({
    query: FETCH_MESSAGES_TOTAL_COUNT_QUERY,
    filters: {
      messagesFilters: {
        projectNanoId,
        taskNanoId,
        pinned: true
      }
    },
    cacheKey: pinnedMessagesCacheKey
  });

  const starredMessagesTotalCount =
    starred?.messages?.paginationInfo?.totalCount;
  const flaggedMessagesTotalCount =
    flagged?.messages?.paginationInfo?.totalCount;
  const pinnedMessagesTotalCount = pinned?.messages?.paginationInfo?.totalCount;

  const onClickStarred = useCallback(() => {
    filterMessages({
      ...messagesFilters,
      favorite: messagesFilters.favorite ? undefined : true
    });
  }, [filterMessages, messagesFilters]);
  const onClickFlagged = useCallback(() => {
    filterMessages({
      ...messagesFilters,
      flagged: messagesFilters.flagged ? undefined : true
    });
  }, [filterMessages, messagesFilters]);

  return (
    <CheckPermissions
      actions={compact([
        MessagesPermissions.READ_MESSAGES_HEADER_JUMP_TO_BUTTON,
        MessagesPermissions.READ_MESSAGES_HEADER_AUTHORS_FILTER,
        MessagesPermissions.READ_MESSAGES_HEADER_VISIBLE_FOR_CLIENTS_FILTER,
        MessagesPermissions.READ_MESSAGES_HEADER_SETTING_BUTTON,
        starredMessagesTotalCount
          ? MessagesPermissions.READ_MESSAGES_HEADER_STARRED_BUTTON
          : null,
        flaggedMessagesTotalCount
          ? MessagesPermissions.READ_MESSAGES_HEADER_FLAGGED_BUTTON
          : null,
        pinnedMessagesTotalCount
          ? MessagesPermissions.READ_MESSAGES_HEADER_PINNED_BUTTON
          : null
      ])}
    >
      <div
        className={cl(
          'border-b',
          isChatGptMode
            ? 'border-purple-500/20 bg-purple-100 dark:bg-purple-950/30'
            : 'dark:border-gray-700'
        )}
      >
        <div className="max-w-screen-lg m-auto">
          <div className="flex items-center px-1 sm:px-3 py-0.5 text-gray-500 dark:text-gray-400 gap-2">
            <div className="flex gap-1 flex-1">
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGES_HEADER_JUMP_TO_BUTTON}
              >
                <ItemMessagesHeaderJumpToButton
                  projectNanoId={projectNanoId}
                  taskNanoId={taskNanoId}
                  messagesFilters={messagesFilters}
                  filterMessages={filterMessages}
                  messages={messages}
                  messagesFetched={messagesFetched}
                  lastMessages={lastMessages}
                  lastMessagesFetched={lastMessagesFetched}
                />
              </CheckPermissions>

              {!isChatGptMode && (
                <>
                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGES_HEADER_SAVED_FILTERS_BUTTON
                    }
                  >
                    <ItemMessagesSavedFiltersButton
                      messagesFilters={messagesFilters}
                      filterMessages={filterMessages}
                      icon={IconsEnum.FILTER_LIST_SOLID}
                      iconClassName="h-4 w-4"
                      tooltipI18nText={words.savedFilters}
                    />
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGES_HEADER_MESSAGES_FILTER
                    }
                  >
                    <MessagesFilterModalButton
                      users={users}
                      usersFetched={usersFetched}
                      usersTotalCount={usersTotalCount}
                      messagesFilters={messagesFilters}
                      filterMessages={filterMessages}
                    />
                  </CheckPermissions>

                  {/* <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGES_HEADER_FILTER_BY_MESSAGE_TYPE_BUTTON
                }
              >
                <ItemMessagesHeaderMessageTypeFilter
                  filterMessages={filterMessages}
                  messagesFilters={messagesFilters}
                />
              </CheckPermissions> */}

                  {/* <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGES_HEADER_FILTER_BY_ATTACHMENT_TYPE_BUTTON
                }
              >
                <ItemMessagesHeaderMessageAttachmentsTypeFilter
                  filterMessages={filterMessages}
                  messagesFilters={messagesFilters}
                />
              </CheckPermissions> */}

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGES_HEADER_AUTHORS_FILTER
                    }
                  >
                    <ItemMessagesHeaderUsersFilter
                      users={users}
                      usersError={usersError}
                      usersFetched={usersFetched}
                      usersTotalCount={usersTotalCount}
                      messagesFilters={messagesFilters}
                      filterMessages={filterMessages}
                    />
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGES_HEADER_VISIBLE_FOR_CLIENTS_FILTER
                    }
                  >
                    <ItemMessagesHeaderVisibleForClientsFilter
                      messagesFilters={messagesFilters}
                      filterMessages={filterMessages}
                    />
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGES_HEADER_STARRED_BUTTON
                    }
                  >
                    <ItemMessagesHeaderFilterButton
                      active={messagesFilters.favorite}
                      count={starredMessagesTotalCount}
                      i18nText={words.starred}
                      icon={IconsEnum.FILLED_STAR}
                      onClick={onClickStarred}
                    />
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGES_HEADER_FLAGGED_BUTTON
                    }
                  >
                    <ItemMessagesHeaderFilterButton
                      active={messagesFilters.flagged}
                      count={flaggedMessagesTotalCount}
                      i18nText={words.flagged}
                      icon={IconsEnum.FLAG}
                      onClick={onClickFlagged}
                    />
                  </CheckPermissions>
                </>
              )}
            </div>

            <div className="flex gap-1">
              {!isChatGptMode && (
                <CheckPermissions
                  action={
                    MessagesPermissions.READ_MESSAGES_HEADER_PINNED_BUTTON
                  }
                >
                  <ItemMessagesHeaderFilterButton
                    active={messagesFilters.pinned}
                    count={pinnedMessagesTotalCount}
                    i18nText={words.pinned}
                    icon={IconsEnum.PIN}
                    onClick={onClickPinnedButton}
                  />
                </CheckPermissions>
              )}

              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGES_HEADER_CHAT_GPT_MODE_FILTER_BUTTON
                }
              >
                <MessagesChatGptModeFilterButton
                  filterMessages={filterMessages}
                  messagesFilters={messagesFilters}
                />
              </CheckPermissions>

              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGES_HEADER_SETTING_BUTTON}
              >
                <MessagesSettingsModalButton
                  filterMessages={filterMessages}
                  messagesFilters={messagesFilters}
                />
              </CheckPermissions>
            </div>
          </div>
        </div>
      </div>
    </CheckPermissions>
  );
}

export default ItemMessagesHeader;
