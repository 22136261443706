import React from 'react';
import some from 'lodash/some';

import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  FetchMessagesCacheKey,
  MessageID,
  MessageMessageTypes,
  MessageProjectUUID,
  MessageTaskUUID,
  MessageUUID
} from '../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../common/hooks/useMessagesCheckable';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { ItemMessagesListBillMessage } from './components/ItemMessagesListBillMessage';
import { ItemMessagesListCurrentUserMessage } from './components/ItemMessagesListCurrentUserMessage';
import { ItemMessagesListDefaultMessage } from './components/ItemMessagesListDefaultMessage';
import { ItemMessagesListPaidInvoiceMessage } from './components/ItemMessagesListPaidInvoiceMessage';
import { ItemMessagesListInvoiceMessage } from './components/ItemMessagesListInvoiceMessage';
import { ItemMessagesListPaidBillMessage } from './components/ItemMessagesListPaidBillMessage';
import { ItemMessagesListResultsMessage } from './components/ItemMessagesListResultsMessage';
import { ItemMessagesListSendingMessage } from './components/ItemMessagesListSendingMessage';
import { ItemMessagesListTrackTimeMessage } from './components/ItemMessagesListTrackTimeMessage';
import { ItemMessagesListAiSearchMessage } from './components/ItemMessagesListAiSearchMessage';
import { ItemMessagesListAiDetectSearchMessage } from './components/ItemMessagesListAiDetectSearchMessage';
import { ItemMessagesListReturnReasonInvoiceMessage } from './components/ItemMessagesListReturnReasonInvoiceMessage';
import { ItemMessagesListWhiteboardLogMessage } from './components/ItemMessagesListWhiteboardLogMessage';
import { ItemMessagesListPaymentProcessingInvoiceMessage } from './components/ItemMessagesListPaymentProcessingInvoiceMessage';
import { ItemMessagesListVersionMessage } from './components/ItemMessagesListVersionMessage';

import {
  ItemMessagesListMessageItem,
  ItemMessagesListMessageOnRemoveSendingMessage
} from './ItemMessagesListMessage.types';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { MessageBffBodyData } from '../../../../../utils/MessageBffBodyData';

import {
  billMessageTypes,
  MessagesPermissions
} from '../../../messagesConstants';
import { ItemMessagesListMessageWrapper } from '../ItemMessagesListMessageWrapper';
import { ItemMessagesListMessageWrapperProps } from '../ItemMessagesListMessageWrapper/ItemMessagesListMessageWrapper';

type ItemMessagesListMessageProps = {
  message: ItemMessagesListMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  prevSameAuthor?: boolean;
  nextSameAuthor?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  onRemoveSendingMessage?: ItemMessagesListMessageOnRemoveSendingMessage;
  jumpToMessage?: (message: { id: MessageID; uuid: MessageUUID }) => void;
  markedUnhappyMessages?: boolean;
} & CheckableMessagesProps &
  ItemMessagesListMessageWrapperProps;

function ItemMessagesListMessage({
  message,
  messagesCacheKey,
  onReplyMessage,
  prevSameAuthor,
  nextSameAuthor,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  togglePreventModalClose,
  onRemoveSendingMessage,
  checkedMessages,
  onSetCheckedMessage,
  jumpToMessage,
  markedUnhappyMessages,
  showDate,
  toggleShowDate
}: ItemMessagesListMessageProps) {
  const currentUser = useCurrentUser();

  if (
    !currentUser.hasPermissions(MessagesPermissions.READ_MESSAGE_BILLS) &&
    some(billMessageTypes, (type) => type === message.messageType)
  ) {
    return null;
  }

  if (
    !currentUser.hasPermissions(MessagesPermissions.READ_MESSAGE_RED) &&
    message.red
  ) {
    return null;
  }

  if (
    !currentUser.hasPermissions(MessagesPermissions.READ_MESSAGE_ARCHIVED) &&
    message.archivedAt
  ) {
    return null;
  }

  if (message.messageType === MessageMessageTypes.FRONTEND_SENDING_MESSAGE) {
    return (
      <ItemMessagesListMessageWrapper
        message={message}
        showDate={showDate}
        toggleShowDate={toggleShowDate}
      >
        <ItemMessagesListSendingMessage
          message={message}
          onRemoveSendingMessage={onRemoveSendingMessage}
        />
      </ItemMessagesListMessageWrapper>
    );
  }

  if (message.body && MessageBffBodyData.isBffData(message.body)) {
    const bffData = MessageBffBodyData.parseBffData(message.body);

    if (bffData?.messageType === 'ai_search' && bffData?.matchingUuid) {
      return (
        <CheckPermissions action={MessagesPermissions.READ_MESSAGE_AI_SEARCH}>
          <ItemMessagesListMessageWrapper
            message={message}
            showDate={showDate}
            toggleShowDate={toggleShowDate}
          >
            <ItemMessagesListAiSearchMessage
              message={message}
              messagesCacheKey={messagesCacheKey}
            />
          </ItemMessagesListMessageWrapper>
        </CheckPermissions>
      );
    }

    if (bffData?.messageType === 'ai_search' && bffData?.detectedItems) {
      return (
        <CheckPermissions action={MessagesPermissions.READ_MESSAGE_AI_SEARCH}>
          <ItemMessagesListMessageWrapper
            message={message}
            showDate={showDate}
            toggleShowDate={toggleShowDate}
          >
            <ItemMessagesListAiDetectSearchMessage
              message={message}
              messagesCacheKey={messagesCacheKey}
            />
          </ItemMessagesListMessageWrapper>
        </CheckPermissions>
      );
    }

    return null;
  }

  if (message.bill) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_BILL}>
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListBillMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
            centered
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (
    message.messageType === MessageMessageTypes.PAID_INVOICE_MESSAGE &&
    message.invoice
  ) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_PAID_INVOICE}>
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListPaidInvoiceMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (
    message.messageType === MessageMessageTypes.REFUND_INVOICE_MESSAGE &&
    message.invoice
  ) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_REFUND_INVOICE}
      >
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListReturnReasonInvoiceMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (
    message.messageType === MessageMessageTypes.CHARGEBACK_INVOICE_MESSAGE &&
    message.invoice
  ) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_CHARGEBACK_INVOICE}
      >
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListReturnReasonInvoiceMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (
    message.messageType ===
      MessageMessageTypes.PAYMENT_PROCESSING_INVOICE_MESSAGE &&
    message.invoice
  ) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_PAYMENT_PROCESSING_INVOICE}
      >
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListPaymentProcessingInvoiceMessage message={message} />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (message.invoice) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_INVOICE}>
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListInvoiceMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (message.messageType === MessageMessageTypes.PAID_MESSAGE) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_PAID_BILL}>
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListPaidBillMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (
    message.messageType === MessageMessageTypes.TRACK_TIME ||
    message.hours > 0 ||
    message.minutes > 0
  ) {
    return (
      <ItemMessagesListMessageWrapper
        message={message}
        showDate={showDate}
        toggleShowDate={toggleShowDate}
      >
        <ItemMessagesListTrackTimeMessage message={message} />
      </ItemMessagesListMessageWrapper>
    );
  }

  if (
    message.whiteboardKeyId &&
    !message.threadKeyId &&
    message.messageType === MessageMessageTypes.WHITEBOARD_LOG_MESSAGE
  ) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_WHITEBOARD_LOG}
      >
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListWhiteboardLogMessage
            message={message}
            togglePreventModalClose={togglePreventModalClose}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (message.isResult && message.task) {
    return (
      <ItemMessagesListMessageWrapper
        message={message}
        showDate={showDate}
        toggleShowDate={toggleShowDate}
      >
        <ItemMessagesListResultsMessage
          togglePreventModalClose={togglePreventModalClose}
          messagesCacheKey={messagesCacheKey}
          message={message}
        />
      </ItemMessagesListMessageWrapper>
    );
  }

  if (message.messageType === MessageMessageTypes.VERSION) {
    return (
      <CheckPermissions action={MessagesPermissions.READ_MESSAGE_VERSION}>
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListVersionMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  if (currentUser.uuid === message.user?.uuid) {
    return (
      <ItemMessagesListMessageWrapper
        message={message}
        showDate={showDate}
        toggleShowDate={toggleShowDate}
      >
        <ItemMessagesListCurrentUserMessage
          message={message}
          messagesCacheKey={messagesCacheKey}
          onReplyMessage={onReplyMessage}
          prevSameAuthor={prevSameAuthor}
          nextSameAuthor={nextSameAuthor}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          togglePreventModalClose={togglePreventModalClose}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
          jumpToMessage={jumpToMessage}
          markedUnhappyMessages={markedUnhappyMessages}
        />
      </ItemMessagesListMessageWrapper>
    );
  }

  if (message.messageType === MessageMessageTypes.PAYMENT_PROCESSING) {
    return (
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_PAYMENT_PROCESSING}
      >
        <ItemMessagesListMessageWrapper
          message={message}
          showDate={showDate}
          toggleShowDate={toggleShowDate}
        >
          <ItemMessagesListDefaultMessage
            message={message}
            messagesCacheKey={messagesCacheKey}
            onReplyMessage={onReplyMessage}
            prevSameAuthor={prevSameAuthor}
            nextSameAuthor={nextSameAuthor}
            forwardMessageProjectUuid={forwardMessageProjectUuid}
            forwardMessageTaskUuid={forwardMessageTaskUuid}
            checkedMessages={checkedMessages}
            onSetCheckedMessage={onSetCheckedMessage}
            jumpToMessage={jumpToMessage}
            markedUnhappyMessages={markedUnhappyMessages}
          />
        </ItemMessagesListMessageWrapper>
      </CheckPermissions>
    );
  }

  return (
    <ItemMessagesListMessageWrapper
      message={message}
      showDate={showDate}
      toggleShowDate={toggleShowDate}
    >
      <ItemMessagesListDefaultMessage
        message={message}
        messagesCacheKey={messagesCacheKey}
        onReplyMessage={onReplyMessage}
        prevSameAuthor={prevSameAuthor}
        nextSameAuthor={nextSameAuthor}
        forwardMessageProjectUuid={forwardMessageProjectUuid}
        forwardMessageTaskUuid={forwardMessageTaskUuid}
        togglePreventModalClose={togglePreventModalClose}
        checkedMessages={checkedMessages}
        onSetCheckedMessage={onSetCheckedMessage}
        jumpToMessage={jumpToMessage}
        markedUnhappyMessages={markedUnhappyMessages}
      />
    </ItemMessagesListMessageWrapper>
  );
}

export default ItemMessagesListMessage;
