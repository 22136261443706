import camelCase from 'lodash/camelCase';

import {
  MessageAssociations,
  MessageUUID
} from '../../../messages/messagesTypes';

const keysToRename = {
  facility: 'product',
  scene: 'lifestyle',
  selectedScenes: 'selectedLifestyles',
  selectedFacilities: 'selectedProducts'
};

type AnyObject = Record<string, any>;

function convertKeysAndReplace<T extends AnyObject | AnyObject[]>(
  obj: T,
  keyMap: Record<string, string> = {}
): T {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysAndReplace(item, keyMap)) as T;
  } else if (obj !== null && typeof obj === 'object') {
    const result = Object.keys(obj).reduce(
      (acc: Record<string, any>, key: string) => {
        let camelCaseKey = camelCase(key);

        if (keyMap[camelCaseKey]) {
          camelCaseKey = keyMap[camelCaseKey];
        }

        acc[camelCaseKey] = convertKeysAndReplace(obj[key], keyMap);

        return acc;
      },
      {} as Record<string, any>
    );

    return result as T;
  }

  return obj;
}

interface MessageBaseType {
  uuid: MessageUUID;
  associations?: MessageAssociations;
}

function convertTaskMessageToMessage<MessageItemType extends MessageBaseType>(
  message: MessageItemType
): MessageItemType {
  const messageAssociations = message.associations
    ? convertKeysAndReplace(message.associations, keysToRename)
    : {};

  return {
    ...message,
    ...messageAssociations
  };
}

export default convertTaskMessageToMessage;
