import { useCallback, useMemo } from 'react';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';

import {
  FetchMessagesFilters,
  FetchMessagesLimit,
  FetchMessagesGqlQuery,
  FetchMessagesCacheKey,
  FetchMessagesSortTypes
} from '../../messagesTypes';

import {
  INITIAL_MESSAGES_FILTERS,
  INITIAL_MESSAGES_LIMIT,
  INITIAL_MESSAGES_SORT
} from '../../messagesConstants';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType,
  InfiniteIndexQueryOnSuccess
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { FetchItemsFilters } from '../../../../types';

interface MessagesOptions<MessageItemType> {
  cacheKey: FetchMessagesCacheKey;
  query: FetchMessagesGqlQuery;
  initialFilters?: FetchMessagesFilters;
  initialLimit?: FetchMessagesLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    onSuccess?: InfiniteIndexQueryOnSuccess<MessageItemType>;
  };
}

const scope = 'messages';

const initialSort = INITIAL_MESSAGES_SORT;

function useMessagesWithCustomPagination<
  MessageItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_MESSAGES_FILTERS,
  initialLimit = INITIAL_MESSAGES_LIMIT,
  options = {}
}: MessagesOptions<MessageItemType>) {
  const {
    addItemCache,
    changeItemsFilters,
    currentFilters,
    currentSort,
    filterItems,
    hasNextPage,
    isFetched,
    isFetchingNextPage,
    isPlaceholderData,
    items,
    itemsError,
    itemsTotalCount,
    loadMoreItems,
    refetch: refetchItems,
    sortItems
  } = useInfiniteIndexQuery<MessageItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  const enabledPreviousItems = useMemo(
    () => !!currentFilters?.id?.gte || !!currentFilters?.id?.lte,
    [currentFilters]
  );

  const {
    items: reverseItems,
    itemsError: reverseItemsError,
    isFetchingNextPage: reverseItemsFetchingNextPage,
    isPlaceholderData: reverseItemsIsPlaceholderData,
    hasNextPage: hasNextReverseItemsPage,
    loadMoreItems: loadMoreReverseItems,
    filterItems: filterReverseItems,
    changeItemsFilters: changeReverseItemsFilters,
    refetch: refetchReverseItems
  } = useInfiniteIndexQuery<MessageItemType>({
    cacheKey: `${cacheKey}-previous`,
    initialFilters,
    initialLimit,
    initialSort,
    options: {
      enabled: enabledPreviousItems,
      enabledPlaceholder: enabledPreviousItems
    },
    query,
    scope
  });

  const messages = useMemo<MessageItemType[]>(
    () =>
      enabledPreviousItems ? uniqBy(concat(items, reverseItems), 'id') : items,
    [enabledPreviousItems, items, reverseItems]
  );

  const filterMessages = useCallback<(nextFilters: FetchItemsFilters) => void>(
    (nextFilters) => {
      nextFilters?.id?.gte
        ? sortItems([FetchMessagesSortTypes.CREATED_AT_ASC])
        : sortItems([FetchMessagesSortTypes.CREATED_AT_DESC]);

      filterItems(nextFilters);

      if (nextFilters?.id?.gte) {
        filterReverseItems({
          ...nextFilters,
          id: {
            lte: nextFilters.id.gte
          }
        });
      }
    },
    [filterItems, filterReverseItems, sortItems]
  );

  const changeMessagesFilters = useCallback<
    (
      changedFilters: Partial<FetchItemsFilters>,
      removeFilters?: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changedFilters?.id?.gte
        ? sortItems([FetchMessagesSortTypes.CREATED_AT_ASC])
        : sortItems([FetchMessagesSortTypes.CREATED_AT_DESC]);

      changeItemsFilters(changedFilters, removeFilters);

      if (changedFilters?.id?.gte) {
        changeReverseItemsFilters(
          {
            ...changedFilters,
            id: {
              lte: changedFilters.id.gte
            }
          },
          removeFilters
        );
      }
    },
    [changeItemsFilters, changeReverseItemsFilters, sortItems]
  );

  const addMessageCache = useCallback<(message?: MessageItemType) => void>(
    (message) => {
      if (
        message &&
        some(
          currentSort,
          (sort) => sort === FetchMessagesSortTypes.CREATED_AT_DESC
        )
      ) {
        addItemCache({
          addItem: message,
          atStart: true
        });
      }
    },
    [addItemCache, currentSort]
  );

  return {
    messages: messages,
    messagesTotalCount: itemsTotalCount,
    messagesError: itemsError || reverseItemsError,
    messagesFetched: isFetched,
    messagesFetchingNextPage: enabledPreviousItems
      ? reverseItemsFetchingNextPage
      : isFetchingNextPage,
    messagesFetchingPreviousPage: enabledPreviousItems && isFetchingNextPage,
    messagesIsPlaceholderData:
      isPlaceholderData || reverseItemsIsPlaceholderData,
    messagesFilters: currentFilters,
    hasNextMessagesPage: enabledPreviousItems
      ? hasNextReverseItemsPage
      : hasNextPage,
    hasPreviousMessagesPage: enabledPreviousItems && hasNextPage,
    loadMoreMessages: enabledPreviousItems
      ? loadMoreReverseItems
      : loadMoreItems,
    loadMorePreviousMessages: enabledPreviousItems && loadMoreItems,
    refetchMessages: enabledPreviousItems ? refetchReverseItems : refetchItems,
    filterMessages,
    changeMessagesFilters,
    addMessageCache
  };
}

export default useMessagesWithCustomPagination;
